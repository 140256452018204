import { createStore, combineReducers, Store, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import draftInsuranceOfferReducer, {
  DraftInsuranceOfferState,
} from './features/draftInsuranceOffer.duck';
import transactionIdReducer, {
  TransactionIdState,
} from './features/transactionId.duck';
import stepDataReducer, { StepDataState } from './features/stepData.duck';
import insuranceOfferReducer, {
  InsuranceOfferState,
} from './features/insuranceOffer.duck';
import policyStateReducer, { PolicyState } from './features/policy.duck';

export interface AppStore {
  draftInsuranceOffer: DraftInsuranceOfferState;
  transactionId: TransactionIdState;
  stepData: StepDataState;
  insuranceOffer: InsuranceOfferState;
  policy: PolicyState;
}

const rootReducer = combineReducers<AppStore>({
  draftInsuranceOffer: draftInsuranceOfferReducer,
  transactionId: transactionIdReducer,
  stepData: stepDataReducer,
  insuranceOffer: insuranceOfferReducer,
  policy: policyStateReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const configureStore = (): Store<AppStore> => {
  const middlewares = [thunk];
  const middlewaresEnhancer = applyMiddleware(...middlewares);

  const composeEnhancer = composeWithDevTools({})(middlewaresEnhancer);

  const store = createStore(rootReducer, undefined, composeEnhancer);
  return store;
};
