import { getBrowserResolutionBreakpointString } from './services/analytics';
import { Document, PolicyDocument } from './services/redux/features/types';

export const getBrandName = (): string => {
  return process.env.REACT_APP_WEBSITE_BRAND as string;
};
export const getStage = (): string => {
  return process.env.REACT_APP_STAGE as string;
};
export const isDocumentDisplayedInPolicySection = (
  document: Document | PolicyDocument
): boolean => {
  return document.displayLabel === 'Hestia Polisa';
};

export const brandToVW6 = getBrandName() === 'vw' ? 'vw6' : getBrandName();

export const ANALYTICS_DEFAULTS = {
  core: {
    stagingEnvironment: null,
    dataLayerVersion: '2.6',
    pageInfo: {
      pageName: '',
      intendedCustomerDeviceType: 'all',
      version: '1.0',
      releaseDate: '2021-04-12',
      language: 'pl',
      market: 'PL',
      publisher: 'DU',
    },
    category: {
      primaryCategory: 'Insurance',
      secondaryCategory: 'MotorInsurance & ExtendedWarranty',
      productVariants: [],
      siteType: 'checkoutSite',
    },
    attributes: {
      journeyType: 'customer-facing-product-journey',
      viewChange: null,
      brand: getBrandName(),
    },
  },
  error: {
    errorCode: null,
    errorMessage: null,
    errorCausingURL: null,
  },
  universally: {
    numberOfProducts: null,
    nps: [
      {
        topic: null,
        rating: null,
      },
    ],
  },
  product: [
    {
      name: '',
      category: 'Insurance',
      productVariants: [],
      attributes: {
        typeOfSale: 'Online sales',
        recurringPayment: null,
        duration: '1',
        durationUnit: 'YEAR',
        startDateOfContract: null,
        endDateOfContract: null,
        registrationDateOwner: null,
      },
      vehicleModel: [
        {
          manufacturer: null,
          name: null,
          descriptionLong: null,
          currentMileage: null,
          currentMileageUnit: 'KILOMETERS',
          currency: null,
        },
      ],
    },
  ],
  dealerData: {
    companyName: null,
    address: {
      street: null,
      zipCode: null,
      city: null,
    },
  },
  traci: {
    _s: null,
    _ee: null,
    _ie: null,
    _a: null,
    _p: null,
    _c: null,
    _t: null,
  },
  form: {
    type: null,
    name: null,
    fieldValues: null,
    lastTouchedField: null,
    errorFields: null,
  },
  design: {
    browserResolutionBreakpoint: getBrowserResolutionBreakpointString(
      window.innerWidth
    ),
  },
  customerData: {
    loginStatus: false,
    loggedInUserGroup: null,
    yearOfBirth: null,
    gender: null,
    address: {
      zipCode: null,
    },
  },
  targeting: {
    pageTargetVersion: null,
    completeVisitPath: [],
  },
  event: [
    {
      eventInfo: {
        eventType: null,
        eventAction: null,
        eventTargetURL: null,
        linkInformation: null,
      },
    },
  ],
};

export const bronsonConfig: {
  [key: string]: { theme: string; version: string };
} = {
  vw: {
    theme: 'vw6',
    version: '11.14.0',
  },
  vwfs: {
    theme: 'bluelabel',
    version: '11.14.0',
  },
};
