import { Transaction } from './types';
import { RootState } from '..';

enum ActionTypes {
  SET_TRANSACTION_ID_DONE = 'statements//SET_TRANSACTION_ID_DONE',
  SET_TRANSACTION_ID_ERROR = 'statements//SET_TRANSACTION_ID_ERROR',
  SET_TRANSACTION_ID_PENDING = 'statements//SET_TRANSACTION_ID_PENDING',
}

export interface TransactionIdState {
  status: string;
  data: Partial<StatementsResponse>;
  error: null | ErrorResponse;
}

export interface StatementsResponse {
  transaction: Transaction;
}

export type StatementsResponseData = StatementsResponse | ErrorResponse;

export interface ErrorResponse {
  message: string;
  displayMessage?: string;
  status?: string;
  transaction?: Transaction;
}

export interface DoneAction {
  type: ActionTypes.SET_TRANSACTION_ID_DONE;
  payload: {
    data: StatementsResponse;
  };
}

export interface ErrorAction {
  type: ActionTypes.SET_TRANSACTION_ID_ERROR;
  payload: {
    errorResponse: ErrorResponse;
  };
}

export interface PendingAction {
  type: ActionTypes.SET_TRANSACTION_ID_PENDING;
}

export type TransactionIdAction = PendingAction | DoneAction | ErrorAction;

export function pending(): PendingAction {
  return {
    type: ActionTypes.SET_TRANSACTION_ID_PENDING,
  };
}

export function done(data: StatementsResponse): DoneAction {
  return {
    type: ActionTypes.SET_TRANSACTION_ID_DONE,
    payload: { data },
  };
}

export function error(errorResponse: ErrorResponse): ErrorAction {
  return {
    type: ActionTypes.SET_TRANSACTION_ID_ERROR,
    payload: {
      errorResponse,
    },
  };
}

const getTransactionIdDataSelector = (state: RootState): string =>
  state.transactionId?.data?.transaction?.localTransactionId as string;

const getTransactionIdErrorSelector = (state: RootState): string =>
  state.transactionId?.error?.transaction?.localTransactionId as string;

export const getTransactionId = (state: RootState): string =>
  getTransactionIdDataSelector(state) ?? getTransactionIdErrorSelector(state);

const initialState: TransactionIdState = {
  status: 'idle',
  data: {},
  error: null,
};

export default function(
  state = initialState,
  action: TransactionIdAction
): TransactionIdState {
  switch (action.type) {
    case ActionTypes.SET_TRANSACTION_ID_PENDING:
      return {
        ...state,
        data: {},
        status: 'pending',
        error: null,
      };
    case ActionTypes.SET_TRANSACTION_ID_DONE:
      return {
        ...state,
        data: action.payload.data,
        status: 'resolved',
        error: null,
      };
    case ActionTypes.SET_TRANSACTION_ID_ERROR:
      return {
        ...state,
        data: {},
        status: 'rejected',
        error: action.payload.errorResponse,
      };
    default:
      return state;
  }
}
