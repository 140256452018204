import {
  Transaction,
  DealerData,
  Offer,
  Document,
  DataPrivacyStatement,
  FinancialProduct,
  Calculation,
  Consent,
  VehicleData,
  TechnicalMessage,
} from './types';
import { RootState } from '..';

export enum ActionTypes {
  FETCH_GET_INSURANCE_OFFER_PENDING = 'insuranceOffer//FETCH_GET_INSURANCE_OFFER_PENDING',
  FETCH_GET_INSURANCE_OFFER_DONE = 'insuranceOffer//FETCH_GET_INSURANCE_OFFER_DONE',
  FETCH_GET_INSURANCE_OFFER_ERROR = 'insuranceOffer//FETCH_GET_INSURANCE_OFFER_ERROR',
}

export type StatusType = 'idle' | 'pending' | 'resolved' | 'rejected';

export interface InsuranceOfferState {
  status: string;
  data: Partial<DataResponse>;
  error: null | ErrorResponse;
}

interface ErrorResponse {
  statusCode: string;
  error: string;
  message: Array<string>;
}

export interface DataResponse {
  transaction: Transaction;
  dealerData: DealerData;
  offers: Offer[];
  documents: Document[];
  technicalMessages: string[];
  dataPrivacyStatement: DataPrivacyStatement;
  financialProduct: FinancialProduct;
}

export type InsuranceOfferResponseData = DataResponse | ErrorResponse;

export interface DoneAction {
  type: ActionTypes.FETCH_GET_INSURANCE_OFFER_DONE;
  payload: {
    data: DataResponse;
  };
}

export interface ErrorAction {
  type: ActionTypes.FETCH_GET_INSURANCE_OFFER_ERROR;
  payload: {
    errorResponse: ErrorResponse;
  };
}

export interface PendingAction {
  type: ActionTypes.FETCH_GET_INSURANCE_OFFER_PENDING;
}

export type InsuranceOfferAction = PendingAction | DoneAction | ErrorAction;

export function pending(): PendingAction {
  return {
    type: ActionTypes.FETCH_GET_INSURANCE_OFFER_PENDING,
  };
}

export function done(data: DataResponse): DoneAction {
  return {
    type: ActionTypes.FETCH_GET_INSURANCE_OFFER_DONE,
    payload: { data },
  };
}

export function error(errorResponse: ErrorResponse): ErrorAction {
  return {
    type: ActionTypes.FETCH_GET_INSURANCE_OFFER_ERROR,
    payload: {
      errorResponse,
    },
  };
}

export const getOffersSelector = (state: RootState): Offer[] =>
  state.insuranceOffer.data?.offers as Offer[];

export const getStatusSelector = (state: RootState): StatusType =>
  state.insuranceOffer.status as StatusType;

export const getDealerDataSelector = (state: RootState): DealerData =>
  state.insuranceOffer.data?.dealerData as DealerData;

export const getDocumentsSelector = (state: RootState): Document[] =>
  state.insuranceOffer.data?.documents as Document[];

export const getFinancialProductSelector = (state: RootState): Calculation => {
  return state.insuranceOffer.data?.financialProduct
    ?.calculation as Calculation;
};

export const getVehicleDataSelector = (state: RootState): VehicleData =>
  (state.insuranceOffer.data?.offers?.[0]?.vehicleData ||
    state.insuranceOffer.data?.offers?.[1]?.vehicleData) as VehicleData;

export const getDataPrivacyStatements = (state: RootState): Consent[] =>
  state.insuranceOffer.data?.dataPrivacyStatement?.consents as Consent[];

export const gettechnicalMessagessSelector = (
  state: RootState
): TechnicalMessage[] =>
  (state.insuranceOffer.data
    ?.technicalMessages as unknown) as TechnicalMessage[];

export const getTransactionIdSelector = (state: RootState): string =>
  state.insuranceOffer.data?.transaction?.localTransactionId as string;

const initialState: InsuranceOfferState = {
  status: 'idle',
  data: {},
  error: null,
};

export default function(
  state = initialState,
  action: InsuranceOfferAction
): InsuranceOfferState {
  switch (action.type) {
    case ActionTypes.FETCH_GET_INSURANCE_OFFER_PENDING:
      return {
        ...state,
        data: {},
        status: 'pending',
        error: null,
      };
    case ActionTypes.FETCH_GET_INSURANCE_OFFER_DONE:
      return {
        ...state,
        data: action.payload.data,
        status: 'resolved',
        error: null,
      };
    case ActionTypes.FETCH_GET_INSURANCE_OFFER_ERROR:
      return {
        ...state,
        data: {},
        status: 'rejected',
        error: action.payload.errorResponse,
      };
    default:
      return state;
  }
}
