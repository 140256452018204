import {
  Transaction,
  DealerData,
  Offer,
  TechnicalMessage,
  Document,
  DataPrivacyStatement,
  Consent,
} from './types';
import { RootState } from '..';

export enum ActionTypes {
  FETCH_GET_DRAFT_INSURANCE_OFFER_PENDING = 'draftInsuranceOffer//FETCH_GET_DRAFT_INSURANCE_OFFER_PENDING',
  FETCH_GET_DRAFT_INSURANCE_OFFER_DONE = 'draftInsuranceOffer//FETCH_GET_DRAFT_INSURANCE_OFFER_DONE',
  FETCH_GET_DRAFT_INSURANCE_OFFER_ERROR = 'draftInsuranceOffer//FETCH_GET_DRAFT_INSURANCE_OFFER_ERROR',
}

export type StatusType = 'idle' | 'pending' | 'resolved' | 'rejected';

export interface DraftInsuranceOfferState {
  status: string;
  data: Partial<DataResponse>;
  error: null | ErrorResponse;
}

interface ErrorResponse {
  statusCode: string;
  error: string;
  message: Array<string>;
}

export interface DataResponse {
  transaction: Transaction;
  dealerData: DealerData;
  offers: Offer[];
  documents: Document[];
  technicalMessages: TechnicalMessage[];
  dataPrivacyStatement: DataPrivacyStatement;
}

export type DraftInsuranceOfferResponseData = DataResponse | ErrorResponse;

export interface DoneAction {
  type: ActionTypes.FETCH_GET_DRAFT_INSURANCE_OFFER_DONE;
  payload: {
    data: DataResponse;
  };
}

export interface ErrorAction {
  type: ActionTypes.FETCH_GET_DRAFT_INSURANCE_OFFER_ERROR;
  payload: {
    errorResponse: ErrorResponse;
  };
}

export interface PendingAction {
  type: ActionTypes.FETCH_GET_DRAFT_INSURANCE_OFFER_PENDING;
}

export type DraftInsuranceOfferAction =
  | PendingAction
  | DoneAction
  | ErrorAction;

export function pending(): PendingAction {
  return {
    type: ActionTypes.FETCH_GET_DRAFT_INSURANCE_OFFER_PENDING,
  };
}

export function done(data: DataResponse): DoneAction {
  const sortedOffersData: DataResponse = {
    ...data,
    offers: data.offers.map(offer => {
      return {
        ...offer,
        variants: offer.variants.sort(
          (offerA, offerB) =>
            offerA.variantValueAmount - offerB.variantValueAmount
        ),
      };
    }),
  };
  return {
    type: ActionTypes.FETCH_GET_DRAFT_INSURANCE_OFFER_DONE,
    payload: { data: sortedOffersData },
  };
}

export function error(errorResponse: ErrorResponse): ErrorAction {
  return {
    type: ActionTypes.FETCH_GET_DRAFT_INSURANCE_OFFER_ERROR,
    payload: {
      errorResponse,
    },
  };
}

export const getConsentsSelector = (state: RootState): Consent[] =>
  state.draftInsuranceOffer?.data?.dataPrivacyStatement?.consents as Consent[];

export const getOffersSelector = (state: RootState): Offer[] =>
  state.draftInsuranceOffer?.data?.offers as Offer[];

export const getDealerDataSelector = (state: RootState): DealerData =>
  state.draftInsuranceOffer?.data?.dealerData as DealerData;

export const getDocumentsSelector = (state: RootState): Document[] =>
  state.draftInsuranceOffer?.data?.documents as Document[];

export const getStatusSelector = (state: RootState): StatusType =>
  state.draftInsuranceOffer?.status as StatusType;

export const getTransactionIdSelector = (state: RootState): string =>
  state.draftInsuranceOffer.data?.transaction?.localTransactionId as string;

export const gettechnicalMessagessSelector = (
  state: RootState
): TechnicalMessage[] =>
  state.draftInsuranceOffer.data?.technicalMessages as TechnicalMessage[];

const initialState: DraftInsuranceOfferState = {
  status: 'idle',
  data: {},
  error: null,
};

export default function(
  state = initialState,
  action: DraftInsuranceOfferAction
): DraftInsuranceOfferState {
  switch (action.type) {
    case ActionTypes.FETCH_GET_DRAFT_INSURANCE_OFFER_PENDING:
      return {
        ...state,
        data: {},
        status: 'pending',
        error: null,
      };
    case ActionTypes.FETCH_GET_DRAFT_INSURANCE_OFFER_DONE:
      return {
        ...state,
        data: action.payload.data,
        status: 'resolved',
        error: null,
      };
    case ActionTypes.FETCH_GET_DRAFT_INSURANCE_OFFER_ERROR:
      return {
        ...state,
        data: {},
        status: 'rejected',
        error: action.payload.errorResponse,
      };
    default:
      return state;
  }
}
