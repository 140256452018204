import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { FC, Suspense, useState, lazy, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Router, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Spinner } from '@vwfs-bronson/bronson-react';

import initI18n from './services/i18n';
import { history } from './services/routing';
import { configureStore } from './services/redux';
import { getBrandName } from './config';

const store = configureStore();

const ConnectedApp = lazy(() => {
  return import('./App');
});

const chooseVwBrand = (brand: string): string => {
  switch (brand) {
    case 'vwfs':
      return 'bluelabel';
    case 'vw':
      return 'vw6';
    case 'vwcv':
      return 'vw';
    case 'vwn':
      return 'vw';
    default:
      return brand;
  }
};

const PositionRestore = withRouter(
  (props: { location: { pathname: string; hash: string } }) => {
    const [location, setLocation] = useState<string>(props.location.pathname);
    if (location !== props.location.pathname) {
      setLocation(props.location.pathname);
      if (props.location.hash && props.location.hash !== '') {
        setTimeout(() => {
          const id = props.location.hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) element.scrollIntoView({ behavior: 'smooth' });
        }, 0);
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }

    return null;
  }
);

const Init: FC = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    Promise.all([initI18n(getBrandName())]).then(() => {
      setLoaded(true);
    });
  }, []);
  return loaded ? (
    <Suspense fallback={<Spinner fullPage />}>
      <Provider store={store}>
        <Router history={history}>
          <PositionRestore />
          <ConnectedApp />
        </Router>
      </Provider>
    </Suspense>
  ) : null;
};

function render(): void {
  const rootElement = document.getElementById('root');
  ReactDOM.render(<Init />, rootElement);
}

declare let module: { hot: any };

if (module.hot) {
  module.hot.accept(render);
}

render();
