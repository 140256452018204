import get from 'lodash/get';

import { RootState } from '..';
import {
  Variant,
  AgreementsValues,
  OfferParams,
  OfferParamsEnhanced,
} from './types';

export enum ActionTypes {
  SET_STEP_DATA = 'stepData//SET_STEP_DATA',
  RESET_STEP_DATA = 'stepData//RESET_STEP_DATA',
}

export interface StepDataState {
  data: Partial<{
    step1: AgreementsValues;
    step2: Variant[];
  }>;
}

interface PayloadData {
  step: string;
  stepData: StepDataTypes;
}

interface StepDataAction {
  type: ActionTypes.SET_STEP_DATA;
  payload: PayloadData;
}

interface ResetFormAction {
  type: ActionTypes.RESET_STEP_DATA;
}

type StepDataActionsType = StepDataAction | ResetFormAction;

export type StepDataTypes =
  | AgreementsValues
  | Variant[]
  | OfferParams
  | OfferParamsEnhanced;

export function setStepData(
  step: string,
  stepData: StepDataTypes
): StepDataAction {
  return {
    type: ActionTypes.SET_STEP_DATA,
    payload: {
      step,
      stepData,
    },
  };
}

export function resetFormData(): ResetFormAction {
  return {
    type: ActionTypes.RESET_STEP_DATA,
  };
}

export const getStepData = (state: RootState, step: string): StepDataTypes =>
  get(state.stepData.data, `${step}`) as StepDataTypes;

const initialState = {
  data: {},
};

export default function(
  state = initialState,
  action: StepDataActionsType
): StepDataState {
  switch (action.type) {
    case ActionTypes.RESET_STEP_DATA:
      return {
        ...state,
        data: {},
      };
    case ActionTypes.SET_STEP_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.step]: action.payload.stepData,
        },
      };
    default:
      return state;
  }
}
