import {
  Transaction,
  Document,
  DataPrivacyStatement,
  FinancialProduct,
  Policy,
} from './types';
import { RootState } from '..';

export enum ActionTypes {
  FETCH_GET_POLICY_PENDING = 'policy//FETCH_GET_POLICY_PENDING',
  FETCH_GET_POLICY_DONE = 'policy//FETCH_GET_POLICY_DONE',
  FETCH_GET_POLICY_ERROR = 'policy//FETCH_GET_POLICY_ERROR',
}

export type StatusType = 'idle' | 'pending' | 'resolved' | 'rejected';

export interface PolicyState {
  status: string;
  data: Partial<DataResponse>;
  error: null | ErrorResponse;
}

interface ErrorResponse {
  statusCode: string;
  error: string;
  message: Array<string>;
}

export interface DataResponse {
  transaction: Transaction;
  offers: Policy[];
  documents: Document[];
  dataPrivacyStatement: DataPrivacyStatement;
  financialProduct: FinancialProduct;
}

export type PolicyResponseData = DataResponse | ErrorResponse;

export interface DoneAction {
  type: ActionTypes.FETCH_GET_POLICY_DONE;
  payload: {
    data: DataResponse;
  };
}

export interface ErrorAction {
  type: ActionTypes.FETCH_GET_POLICY_ERROR;
  payload: {
    errorResponse: ErrorResponse;
  };
}

export interface PendingAction {
  type: ActionTypes.FETCH_GET_POLICY_PENDING;
}

export type PolicyAction = PendingAction | DoneAction | ErrorAction;

export function pending(): PendingAction {
  return {
    type: ActionTypes.FETCH_GET_POLICY_PENDING,
  };
}

export function done(data: DataResponse): DoneAction {
  return {
    type: ActionTypes.FETCH_GET_POLICY_DONE,
    payload: { data },
  };
}

export function error(errorResponse: ErrorResponse): ErrorAction {
  return {
    type: ActionTypes.FETCH_GET_POLICY_ERROR,
    payload: {
      errorResponse,
    },
  };
}

export const getPolicySelector = (state: RootState): Policy[] =>
  state.policy.data?.offers as Policy[];

export const getStatusSelector = (state: RootState): StatusType =>
  state.policy.status as StatusType;

const initialState: PolicyState = {
  status: 'idle',
  data: {},
  error: null,
};

export default function(
  state = initialState,
  action: PolicyAction
): PolicyState {
  switch (action.type) {
    case ActionTypes.FETCH_GET_POLICY_PENDING:
      return {
        ...state,
        data: {},
        status: 'pending',
        error: null,
      };
    case ActionTypes.FETCH_GET_POLICY_DONE:
      return {
        ...state,
        data: action.payload.data,
        status: 'resolved',
        error: null,
      };
    case ActionTypes.FETCH_GET_POLICY_ERROR:
      return {
        ...state,
        data: {},
        status: 'rejected',
        error: action.payload.errorResponse,
      };
    default:
      return state;
  }
}
