/**
 * This service is a bridge between the application and whatever analytics
 * software is used.
 *
 * By defining the REACT_APP_ANALYTICS environment variable we are able to
 * either export the real or mocked implementation, so that from the application
 * point of view nothing changes and it can continue calling the analytics logic
 * as per usuall.
 */

import { merge, isEmpty, isEqual } from 'lodash';

interface AnalyticsObj {
  core: {
    stagingEnvironment: string | null;
    dataLayerVersion: string;
    pageInfo: {
      pageName: string | null;
      intendedCustomerDeviceType: string;
      version: string;
      releaseDate: string;
      language: string;
      market: string;
      publisher: string;
    };
    category: {
      primaryCategory: string;
      secondaryCategory: string;
      productVariants: { name: string | null }[];
      siteType: string;
    };
    attributes: {
      journeyType: string;
      viewChange: string | null;
      brand: string | undefined;
    };
  };
  error: {
    errorCode: string | null;
    errorMessage: string | null;
    errorCausingURL: string | null;
  };
  universally: {
    numberOfProducts: string | null;
    nps: {
      topic: string | null;
      rating: string | null;
    }[];
  };
  product: {
    name: string;
    category: string;
    productVariants: string[];
    attributes: {
      typeOfSale: string | null;
      recurringPayment: string | null;
      duration: string | null;
      durationUnit: string | null;
      startDateOfContract: string | null;
      endDateOfContract: string | null;
      registrationDateOwner: Date | null;
    };
    vehicleModel: {
      manufacturer: string | null;
      name: string | null;
      descriptionLong: string | null;
      currentMileage: string | null;
      currentMileageUnit: string | null;
      currency: string | null;
    }[];
  }[];
  dealerData: {
    companyName: string | null;
    address: {
      street: string | null;
      zipCode: string | null;
      city: string | null;
    };
  };
  traci: {
    _s: string | null;
    _ee: string | null;
    _ie: string | null;
    _a: string | null;
    _p: string | null;
    _c: string | null;
    _t: null;
  };
  form: {
    type: string | null;
    name: string | null;
    fieldValues: string | null;
    lastTouchedField: string | null;
    errorFields: null;
  };
  design: {
    browserResolutionBreakpoint: string | null;
  };
  customerData: {
    loginStatus: boolean;
    loggedInUserGroup: string | null;
    yearOfBirth: string | null;
    gender: string | null;
    address: {
      zipCode: null;
    };
  };
  targeting: {
    pageTargetVersion: string | null;
    completeVisitPath: string[];
  };
  event: {
    eventInfo: {
      eventType: string | null;
      eventAction: string | null;
      eventTargetURL: string | null;
      linkInformation: string | null;
    };
  }[];
}

interface AnalyticsPageObj {
  pageName: string;
  viewChange?: string;
  eventType?: string;
  eventAction?: string;
  linkInformation?: string;
  eventType2?: string;
  eventAction2?: string;
  linkInformation2?: string;
  eventType3?: string;
  eventAction3?: string;
  linkInformation3?: string;
  errorCode?: string;
  errorMessage?: string;
  errorCausingURL?: string;
  npsTopic?: string;
  npsRating?: string;
  npsTopic2?: string;
  npsRating2?: string;
  npsTopic3?: string;
  npsRating3?: string;
  errorFields?: string;
  browserResolutionBreakpoint?: number;
  product?: {
    name?: string;
    category?: string;
    productVariants?: string[];
    attributes?: {
      typeOfSale?: string | null;
      recurringPayment?: string | null;
      duration?: string | null;
      durationUnit?: string | null;
      startDateOfContract?: string | null;
      endDateOfContract?: string | null;
      registrationDateOwner?: Date | null;
    };
    vehicleModel?: {
      manufacturer?: string | null;
      name?: string | null;
      descriptionLong?: string | null;
      currentMileage?: string | null;
      currentMileageUnit?: string | null;
      currency?: string | null;
    }[];
  }[];
  dealerData?: {
    companyName?: string | null;
    address?: {
      street?: string | null;
      zipCode?: string | null;
      city?: string | null;
    };
  };
}

interface AnalyticsProductVariants {
  pageName?: string;
  viewChange?: string;
  categoryProductVariants?: { name: string | null }[];
  eventType?: string;
  eventAction?: string;
  linkInformation?: string;
  eventType2?: string;
  eventAction2?: string;
  linkInformation2?: string;
  eventType3?: string;
  eventAction3?: string;
  linkInformation3?: string;
  browserResolutionBreakpoint?: number;
  product?: {
    name?: string;
    category?: string;
    productVariants?: string[];
    attributes?: {
      typeOfSale?: string | null;
      recurringPayment?: string | null;
      duration?: string | null;
      durationUnit?: string | null;
      startDateOfContract?: string | Date | null;
      endDateOfContract?: string | Date | null;
      registrationDateOwner?: Date | null;
    };
    vehicleModel?: {
      manufacturer?: string | null;
      name?: string | null;
      descriptionLong?: string | null;
      currentMileage?: string | null;
      currentMileageUnit?: string | null;
      currency?: string | null;
    }[];
  }[];
  dealerData?: {
    companyName?: string | null;
    address?: {
      street?: string | null;
      zipCode?: string | null;
      city?: string | null;
    };
  };
}

declare global {
  interface Window {
    // eslint-disable-next-line camelcase
    du_digitalData: AnalyticsObj;
    _satellite: {
      track: (name: string) => void;
      pageBottom: () => void;
    };
  }
}

export const getBrowserResolutionBreakpointString = (width: number): string => {
  if (width <= 480) return 'xs';
  // eslint-disable-next-line no-else-return
  else if (width > 480 && width <= 720) return 's';
  // eslint-disable-next-line no-else-return
  else if (width > 720 && width <= 960) return 'm';
  // eslint-disable-next-line no-else-return
  else if (width > 960 && width <= 1280) return 'l';
  // eslint-disable-next-line no-else-return
  else if (width > 1280 && width <= 1600) return 'xl';
  // eslint-disable-next-line no-else-return
  else return 'xxl';
};

const removeEmpty = (obj: any) => {
  const newObj = Array.isArray(obj) ? [] : {};
  Object.keys(obj).forEach(key => {
    if (obj[key] === Object(obj[key]) && !isEmpty(obj[key])) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newObj[key] = removeEmpty(obj[key]);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    else if (obj[key] !== undefined && obj[key] !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

function cleanAnalyticsPageObj(event: any) {
  let prev: any = { ...event };
  let result = removeEmpty(prev);
  // eslint-disable-next-line no-plusplus,for-direction
  for (let i = 1; i > 0; i++) {
    if (isEqual(result, prev)) {
      break;
    } else {
      prev = { ...result };
      result = removeEmpty(result);
    }
  }
  return result;
}

function cleanInputError(obj: any) {
  if (obj?.core?.attributes) {
    // eslint-disable-next-line no-param-reassign
    delete obj.core.attributes.viewChange;
  }
  // eslint-disable-next-line no-param-reassign
  delete obj.form;
  return cleanAnalyticsPageObj(obj);
}

function cleanEvents(obj: any) {
  // clean event object before merge with new events
  if (!isEmpty(obj)) {
    // eslint-disable-next-line no-param-reassign
    obj.event = [];
  }
}

const mock = {
  default: {} as AnalyticsObj,
  isLive: false,

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  pageBottom: function pageBottom(): void {},

  setDefaults: function setDefaults(obj: AnalyticsObj): void {
    Object.assign(this.default, cleanAnalyticsPageObj(obj));
  },

  trackOfferedVariants: function trackOfferedVariants(
    obj: AnalyticsProductVariants
  ): void {
    const newStructure = {
      core: {
        pageInfo: {
          pageName:
            obj.pageName || window.du_digitalData?.core?.pageInfo?.pageName,
        },
        attributes: {
          viewChange: obj.viewChange,
        },
        category: {
          productVariants: obj.categoryProductVariants,
        },
      },
      product: obj.product,
      dealerData: obj.dealerData,
      event: [
        {
          eventInfo: {
            eventType: obj.eventType,
            eventAction: obj.eventAction,
            linkInformation: obj.linkInformation,
          },
        },
        {
          eventInfo: {
            eventType: obj.eventType2,
            eventAction: obj.eventAction2,
            linkInformation: obj.linkInformation2,
          },
        },
        {
          eventInfo: {
            eventType: obj.eventType3,
            eventAction: obj.eventAction3,
            linkInformation: obj.linkInformation3,
          },
        },
      ],
    };
    this.default.product = [];
    cleanEvents(this.default);
    Object.assign(
      this.default,
      merge(
        {},
        this.default,
        cleanAnalyticsPageObj(newStructure)
      ) as AnalyticsObj
    );
    window.du_digitalData = merge(
      {},
      this.default,
      cleanAnalyticsPageObj(newStructure)
    ) as AnalyticsObj;
  },

  trackVariants: function trackVariants(obj: AnalyticsProductVariants): void {
    const newStructure = {
      core: {
        pageInfo: {
          pageName:
            obj.pageName || window.du_digitalData.core.pageInfo.pageName,
        },
        attributes: {
          viewChange: obj.viewChange,
        },
      },
      product: obj.product,
      event: [
        {
          eventInfo: {
            eventType: obj.eventType,
            eventAction: obj.eventAction,
            linkInformation: obj.linkInformation,
          },
        },
        {
          eventInfo: {
            eventType: obj.eventType2,
            eventAction: obj.eventAction2,
            linkInformation: obj.linkInformation2,
          },
        },
        {
          eventInfo: {
            eventType: obj.eventType3,
            eventAction: obj.eventAction3,
            linkInformation: obj.linkInformation3,
          },
        },
      ],
      design: {
        browserResolutionBreakpoint: obj.browserResolutionBreakpoint
          ? getBrowserResolutionBreakpointString(
              obj.browserResolutionBreakpoint
            )
          : null,
      },
    };
    this.default.product = [];
    cleanEvents(this.default);
    Object.assign(
      this.default,
      merge(
        {},
        this.default,
        cleanAnalyticsPageObj(newStructure)
      ) as AnalyticsObj
    );
    window.du_digitalData = merge(
      {},
      this.default,
      cleanAnalyticsPageObj(newStructure)
    ) as AnalyticsObj;
  },

  track: function track(obj: AnalyticsPageObj): void {
    const newStructure = {
      core: {
        pageInfo: {
          pageName:
            obj.pageName || window.du_digitalData.core.pageInfo.pageName,
        },
        attributes: {
          viewChange: obj.viewChange,
        },
      },
      universally: {
        nps: [
          {
            topic: obj.npsTopic,
            rating: obj.npsRating,
          },
          {
            topic: obj.npsTopic2,
            rating: obj.npsRating2,
          },
          {
            topic: obj.npsTopic3,
            rating: obj.npsRating3,
          },
        ],
      },
      event: [
        {
          eventInfo: {
            eventType: obj.eventType,
            eventAction: obj.eventAction,
            linkInformation: obj.linkInformation,
          },
        },
        {
          eventInfo: {
            eventType: obj.eventType2,
            eventAction: obj.eventAction2,
            linkInformation: obj.linkInformation2,
          },
        },
        {
          eventInfo: {
            eventType: obj.eventType3,
            eventAction: obj.eventAction3,
            linkInformation: obj.linkInformation3,
          },
        },
      ],
      design: {
        browserResolutionBreakpoint: obj.browserResolutionBreakpoint
          ? getBrowserResolutionBreakpointString(
              obj.browserResolutionBreakpoint
            )
          : null,
      },
    };
    cleanEvents(this.default);
    Object.assign(
      this.default,
      merge(
        {},
        obj.viewChange === 'Input Error'
          ? this.default
          : cleanInputError(this.default),
        cleanAnalyticsPageObj(newStructure)
      ) as AnalyticsObj
    );
    window.du_digitalData = merge(
      {},
      this.default,
      cleanAnalyticsPageObj(newStructure)
    ) as AnalyticsObj;
  },

  trackError: function track(obj: AnalyticsPageObj): void {
    const newStructure = {
      core: {
        pageInfo: {
          pageName:
            obj.pageName || window.du_digitalData.core.pageInfo.pageName,
        },
        attributes: {
          viewChange: obj.viewChange,
        },
      },
      universally: {
        nps: [
          {
            topic: obj.npsTopic,
            rating: obj.npsRating,
          },
          {
            topic: obj.npsTopic2,
            rating: obj.npsRating2,
          },
          {
            topic: obj.npsTopic3,
            rating: obj.npsRating3,
          },
        ],
      },
      event: [
        {
          eventInfo: {
            eventType: obj.eventType,
            eventAction: obj.eventAction,
            linkInformation: obj.linkInformation,
          },
        },
        {
          eventInfo: {
            eventType: obj.eventType2,
            eventAction: obj.eventAction2,
            linkInformation: obj.linkInformation2,
          },
        },
        {
          eventInfo: {
            eventType: obj.eventType3,
            eventAction: obj.eventAction3,
            linkInformation: obj.linkInformation3,
          },
        },
      ],
      error: {
        errorCode: obj.errorCode,
        errorMessage: obj.errorMessage,
        errorCausingURL: obj.errorCausingURL,
      },
      form: {
        errorFields: obj.errorFields,
      },
      design: {
        browserResolutionBreakpoint: obj.browserResolutionBreakpoint
          ? getBrowserResolutionBreakpointString(
              obj.browserResolutionBreakpoint
            )
          : null,
      },
    };

    cleanEvents(this.default);
    Object.assign(
      this.default,
      merge(
        {},
        this.default,
        cleanAnalyticsPageObj(newStructure)
      ) as AnalyticsObj
    );
    window.du_digitalData = merge(
      {},
      this.default,
      cleanAnalyticsPageObj(newStructure)
    ) as AnalyticsObj;
  },

  viewChange: function viewChange(): void {
    window._satellite && window._satellite.track('viewChange') // eslint-disable-line
  },

  page: function page(): void {
    if (!this.isLive) {
      // eslint-disable-next-line no-console
      console.log('track page', window.du_digitalData);
    }
    window._satellite && window._satellite.track('page') // eslint-disable-line
  },

  interaction: function interaction(): void {
    if (!this.isLive) {
      // eslint-disable-next-line no-console
      console.log('track interaction', window.du_digitalData);
    }
    window._satellite && window._satellite.track('interaction') // eslint-disable-line
  },

  download: function download(): void {
    if (!this.isLive) {
      // eslint-disable-next-line no-console
      console.log('track download', window.du_digitalData);
    }
    window._satellite && window._satellite.track('download') // eslint-disable-line
  },
};

const real = {
  ...mock,
  isLive: true,
  pageBottom: function pageBottom(): void {
    window._satellite && window._satellite.pageBottom() // eslint-disable-line
  },
};

let implementation = mock // eslint-disable-line

if (process.env.REACT_APP_ANALYTICS !== '') {
  implementation = real;
}

export { implementation as Analytics };
